import React, { ReactElement, MouseEvent } from 'react';
import _startCase from 'lodash/startCase';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import { kyruusFormatHTMLMessage, KyruusFormattedMessage } from '@kyruus/intl';
import { CloseIcon } from '@biome-design-system/icons/CloseIcon';
import { PMCConfig } from '@kyruus/types';
import Button from '@kyruus/button';

import {
  Log,
  SearchSummary,
  SearchModification,
  SearchFacetWithAppliedTerms
} from 'Src/types';
import { BasicContent } from './BasicContent';
import { LocationContent } from './LocationContent';
import { TypeaheadContent } from './TypeaheadContent';
import { Filter, FilterHeader, FilterTitle } from '../../styles';
import messages from './messages';

interface BaseContentProps {
  searchSummary: SearchSummary;
  facet: SearchFacetWithAppliedTerms;
  customerConfig: PMCConfig;
  updateSearch: (modifications: SearchModification[]) => void;
  log: Log;
  handleClose: (event: MouseEvent, reason: 'closeButton') => void;
}

type ContentProps = InjectedIntlProps & BaseContentProps;

export const Content = injectIntl(
  ({
    searchSummary,
    facet,
    customerConfig,
    updateSearch,
    log,
    handleClose,
    intl
  }: ContentProps) => {
    const isSearchableLocation =
      facet.allow_location_change !== false || !searchSummary.location;

    const facetLabelMessageDescriptor = {
      id: `field.name.${facet.field}`,
      defaultMessage: _startCase(facet.field),
      description: 'The name displayed above the facet'
    };
    const facetIsComposite = !!facet && !!facet?.composite;

    let filterContent = {};
    switch (facet.type) {
      case 'location':
        filterContent = (
          <LocationContent
            locationFacet={facet}
            customerConfig={customerConfig}
            log={log}
            isSearchableLocation={isSearchableLocation}
            searchSummary={searchSummary}
            updateSearch={updateSearch}
          />
        );
        break;
      case 'typeahead':
        filterContent = (
          <TypeaheadContent
            facet={facet}
            hideCount={facetIsComposite}
            updateSearch={updateSearch}
          />
        );
        break;
      default:
        filterContent = (
          <BasicContent
            facet={facet}
            hideCount={facetIsComposite}
            updateSearch={updateSearch}
            labelName={facetLabelMessageDescriptor}
          />
        );
    }

    return (
      <Filter>
        <FilterHeader>
          <FilterTitle>
            <KyruusFormattedMessage {...facetLabelMessageDescriptor} />
          </FilterTitle>
          <Button
            mode="flat"
            type="button"
            data-testid="close-filter-popover"
            aria-label={kyruusFormatHTMLMessage(
              intl,
              messages.closeFilterPopoverAriaLabel
            )}
            onClick={(e: MouseEvent) => {
              handleClose(e, 'closeButton');
            }}
          >
            <CloseIcon color="inherit" fontSize="medium" />
          </Button>
        </FilterHeader>
        {filterContent}
      </Filter>
    );
  }
  // TODO: remove this typecast once we have fully migrated to react 17
) as any as (props: BaseContentProps) => ReactElement;
