import React, { ReactElement } from 'react';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import { KyruusFormattedMessage, kyruusFormatMessage } from '@kyruus/intl';
import _kebabCase from 'lodash/kebabCase';
// eslint-disable-next-line no-restricted-imports
import { FormattedMessage } from 'react-intl';

import { Term } from 'Src/types';
import { ShowMoreResultsButton } from '../../../styles';
import messages from './messages';

interface BaseShowMoreButtonProps {
  allTerms: Term[];
  facetName: string;
  hideTerms: boolean;
  labelName: Pick<
    FormattedMessage.Props,
    'id' | 'description' | 'defaultMessage'
  >;
  maxVisible: number;
  onClick: () => void;
  terms: Term[];
}

type ShowMoreButtonProps = InjectedIntlProps & BaseShowMoreButtonProps;

export const ShowMoreButton = injectIntl(
  ({
    allTerms,
    facetName,
    hideTerms,
    intl,
    labelName,
    maxVisible,
    onClick,
    terms
  }: ShowMoreButtonProps) => {
    if (allTerms.length <= maxVisible || terms.length === 0) return null;

    let showMoreLessText;
    if (hideTerms) {
      showMoreLessText = <KyruusFormattedMessage {...messages.showmore} />;
    } else {
      showMoreLessText = <KyruusFormattedMessage {...messages.showless} />;
    }

    return (
      <ShowMoreResultsButton
        id={_kebabCase(facetName) + '-facet-show-hide'}
        data-testid={_kebabCase(facetName) + '-facet-show-hide'}
        onClick={onClick}
        aria-label={`${kyruusFormatMessage(
          intl,
          hideTerms ? messages.showmore : messages.showless
        )} ${kyruusFormatMessage(intl, labelName)}`}
        mode="flat"
      >
        {showMoreLessText}
      </ShowMoreResultsButton>
    );
  }
) as any as (props: BaseShowMoreButtonProps) => ReactElement;

ShowMoreResultsButton.displayName = 'ShowMoreButton';
