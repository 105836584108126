import React from 'react';
import _kebabCase from 'lodash/kebabCase';

import { KyruusFormattedMessage } from '@kyruus/intl';
import { Term } from 'Src/types';
import { V9_DIRECT_BOOK_CAPABLE_FILTER } from '../../../../utils/constants';
import { StyledLabel, StyledLabelTextAndTermCountContainer } from './styles';

interface BasicContentFilterProps {
  term: Term;
  facetType: string;
  facetName: string;
  hideCount: boolean;
  toggleFilter: (filterParam: string) => void;
}

export const BasicContentFilter = ({
  term,
  facetType,
  facetName,
  hideCount,
  toggleFilter
}: BasicContentFilterProps) => {
  const id = 'facet-' + _kebabCase(term.value) + '-' + _kebabCase(facetName);
  const dataTestId = `facet-${_kebabCase(term.value)}-${_kebabCase(facetName)}`;
  const isBookOnlineFacet = term.filter_param === V9_DIRECT_BOOK_CAPABLE_FILTER;

  const filterLabelMessageDescriptor = {
    id: `field.value.${facetName}.${term.value}`,
    defaultMessage: term.value,
    description: 'The label for the filter'
  };
  return (
    <li>
      <StyledLabel htmlFor={id} className="pr-s" id={id + '-label'}>
        <input
          id={id}
          data-testid={dataTestId}
          type={facetType}
          onClick={() => toggleFilter(term.filter_param)}
          defaultChecked={term.applied}
          name={facetName}
          className="mr-xs"
        />
        <StyledLabelTextAndTermCountContainer>
          {isBookOnlineFacet ? (
            <span
              className="icon-event-available fc-positive fs-l va-b mr-xs"
              id="book-online-facet-icon"
            />
          ) : null}
          <KyruusFormattedMessage {...filterLabelMessageDescriptor} />
          {!hideCount && term.count ? (
            <span className="count">{' '}({term.count})</span>
          ) : null}
        </StyledLabelTextAndTermCountContainer>
      </StyledLabel>
    </li>
  );
};
