import React, { ReactElement } from 'react';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import { kyruusFormatHTMLMessage, kyruusFormatMessage } from '@kyruus/intl';

import { SearchModification, Term } from 'Src/types';
import { RemoveFilterChipIcon, FilterChip } from '../../styles';
import { CHIP_ROLE } from '../../constants';
import messages from '../messages';
import { getChipMessage } from '../utils';
import { BaseChipProps } from '.';

interface BaseAppliedChipProps extends BaseChipProps {
  updateSearch: (modifications: SearchModification[]) => void;
}

type AppliedChipProps = InjectedIntlProps & BaseAppliedChipProps;

export const AppliedChip = injectIntl(
  ({
    facet,
    index,
    intl,
    onClick,
    updateSearch,
    log,
    ...props
  }: AppliedChipProps) => {
    const excessAppliedFiltersCount = facet.appliedTerms.length - 1;

    const handleDelete = () => {
      if (facet.appliedTerms.length) {
        const queryModifications: SearchModification[] = facet.appliedTerms.map(
          (appliedFilter: Term) => ({
            action: 'delete_key_value',
            key: 'filter',
            value: `${facet.field}:${appliedFilter.value}`
          })
        );
        updateSearch(queryModifications);
      }
    };

    const chipMessage = getChipMessage(facet);

    const termCount =
      excessAppliedFiltersCount > 0 ? ` +${excessAppliedFiltersCount}` : '';
    const message = kyruusFormatHTMLMessage(intl, chipMessage);
    const chipLabel = `${message}${termCount}`;

    return (
      <FilterChip
        {...props}
        aria-label={kyruusFormatMessage(intl, messages.filterChipAriaLabel, {
          chipLabel
        })}
        color="primary"
        role={CHIP_ROLE}
        data-testid={`filter-chip-${facet.field}`}
        data-index={index}
        label={chipLabel}
        onClick={onClick}
        onDelete={handleDelete}
        deleteIcon={
          <RemoveFilterChipIcon
            size="1.6em"
            aria-hidden="true"
            title={kyruusFormatHTMLMessage(
              intl,
              messages.removeFilterChipLabel
            )}
          />
        }
      />
    );
  }
  // TODO: remove this typecast once we have fully migrated to react 17
) as any as (props: BaseAppliedChipProps) => ReactElement;
