import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';
import { KyruusFormattedMessage } from '@kyruus/intl';
import Modal from '@kyruus/modal';
import Button from '@kyruus/button';
import AppointmentInfo from './appointment-info';
import { AppointmentInfoContainer } from './styles';

const messages = defineMessages({
  availabilityModalConfirm: {
    id: 'availabilitymodal.buttontext.confirm',
    description: 'Button text for availability-modal "confirm" button.',
    defaultMessage: 'OK'
  },
  availabilityModalCancel: {
    id: 'availabilitymodal.buttontext.cancel',
    description: 'Button text for availability-modal "cancel" button.',
    defaultMessage: 'Cancel'
  }
});

const ModalBodyApptInfo = ({
  patientRel,
  purpose,
  onPatientRelSelect,
  onSelectPurpose,
  patientRelOptions,
  providerDisplayName,
  providerPhone,
  availabilityButtonsConfig,
  isPurposeLoading,
  log,
  purposeHasNoAvailability,
  patientRelHasNoAvailability,
  hidePatientRel,
  apptPurposeFilterProps,
  apptInfoFormName
}) => (
  <AppointmentInfoContainer data-testid="AvailabilityModal__body">
    <AppointmentInfo
      isPurposeLoading={isPurposeLoading}
      patientRelOptions={patientRelOptions}
      patientRel={patientRel}
      purpose={purpose}
      onPatientRelSelect={onPatientRelSelect}
      onSelectPurpose={onSelectPurpose}
      providerDisplayName={providerDisplayName}
      providerPhone={providerPhone}
      availabilityButtonsConfig={availabilityButtonsConfig}
      log={log}
      purposeHasNoAvailability={purposeHasNoAvailability}
      patientRelHasNoAvailability={patientRelHasNoAvailability}
      hidePatientRel={hidePatientRel}
      apptPurposeFilterProps={apptPurposeFilterProps}
      formName={apptInfoFormName}
    />
  </AppointmentInfoContainer>
);

function ModalContainer(props) {
  const {
    patientRel,
    purpose,
    onPatientRelSelect,
    onSelectPurpose,
    isOpen,
    handleCloseModal,
    handleConfirmModal,
    patientRelOptions,
    providerDisplayName,
    providerPhone,
    availabilityButtonsConfig,
    isPurposeLoading,
    log,
    purposeHasNoAvailability,
    patientRelHasNoAvailability,
    hidePatientRel,
    apptPurposeFilterProps
  } = props;

  const [shouldRenderModalFullscreen, setShouldRenderModalFullscreen] =
    useState(false);

  useEffect(() => {
    if (window && window.matchMedia) {
      const mq = window.matchMedia('(max-width: 420px)');

      // check on mount if the screen width indicates the modal needs to render fullscreen
      setShouldRenderModalFullscreen(mq.matches);

      // otherwise, listen for changes to screen width and update component state accordingly
      mq.addListener(({ matches }) => {
        setShouldRenderModalFullscreen(matches);
      });
    }
  }, []);

  const apptInfoFormName = 'appointment-info';

  return (
    <Modal
      body={ModalBodyApptInfo({
        patientRel,
        onPatientRelSelect,
        onSelectPurpose,
        purpose,
        patientRelOptions,
        providerDisplayName,
        providerPhone,
        availabilityButtonsConfig,
        isPurposeLoading,
        log,
        purposeHasNoAvailability,
        patientRelHasNoAvailability,
        hidePatientRel,
        apptPurposeFilterProps,
        apptInfoFormName
      })}
      maxWidth={'md'}
      open={isOpen}
      onClose={() => handleCloseModal('close_modal')}
      primaryButton={
        <Button
          data-testid="AvailabilityModal__confirm-btn"
          disabled={!purpose || purposeHasNoAvailability}
          mode="primary"
          onClick={handleConfirmModal}
          type="submit"
          form={apptInfoFormName}
        >
          <KyruusFormattedMessage {...messages.availabilityModalConfirm} />
        </Button>
      }
      secondaryButton={
        <Button
          data-testid="AvailabilityModal__cancel-btn"
          mode="flat"
          onClick={() => handleCloseModal('cancel_button')}
        >
          <KyruusFormattedMessage {...messages.availabilityModalCancel} />
        </Button>
      }
      topAligned={true}
      fullScreen={shouldRenderModalFullscreen}
    />
  );
}

ModalContainer.defaultProps = {
  patientRel: '',
  purpose: null,
  onPatientRelSelect: () => {},
  onSelectPurpose: () => {},
  isOpen: false,
  handleCloseModal: () => {},
  handleConfirmModal: () => {},
  patientRelOptions: {},
  hidePatientRel: false
};

ModalContainer.propTypes = {
  patientRel: PropTypes.string,
  purpose: PropTypes.object,
  onPatientRelSelect: PropTypes.func,
  onSelectPurpose: PropTypes.func,
  isOpen: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  handleConfirmModal: PropTypes.func,
  patientRelOptions: PropTypes.object,
  hidePatientRel: PropTypes.bool,
  apptPurposeFilterProps: PropTypes.object
};

export default ModalContainer;
