import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { kyruusFormatMessage } from '@kyruus/intl';

import { Disclaimer, Pagination, PoweredByLogo, Footer } from './styles';
import { useReferralCrmConnector } from '../referral-crm-connector';

import { MODULES, isModuleEnabled } from 'Common/config';

const messages = defineMessages({
  disclaimertext: {
    id: 'providerlist.footer.disclaimertext',
    description:
      "Disclaimer text stating that providers' availability is subject to change",
    defaultMessage: 'Appointment availability is subject to change'
  }
});

const SearchFooter = ({
  searchSummary,
  showDisclaimer,
  totalPages,
  getUpdatedSearch,
  config,
  log,
  intl
}) => {
  const { connector } = useReferralCrmConnector(config);
  const showPagination = totalPages > 1;
  /**
   * KENG-28970
   * Adding some extra padding to the footer when crm is enabled
   * and referral list drawer is open
   */
  const hasReferrals =
    isModuleEnabled(config, MODULES.REFERRAL) && connector.hasReferrals();

  return (
    <Footer
      id="search-footer"
      hasReferrals={hasReferrals}
      showPagination={showPagination}
      showDisclaimer={showDisclaimer}
    >
      {showPagination && (
        <Pagination
          searchSummary={searchSummary}
          totalPages={totalPages}
          getUpdatedSearch={getUpdatedSearch}
          log={log}
        />
      )}
      <PoweredByLogo config={config} />
      {showDisclaimer && (
        <Disclaimer id="availability-disclaimer-text">
          {`*${kyruusFormatMessage(intl, messages.disclaimertext)}`}
        </Disclaimer>
      )}
    </Footer>
  );
};
export default injectIntl(SearchFooter);
